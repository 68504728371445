import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { mergeMap, catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';
import { CreateCandidateResponse, CreateApplicationPayload, RegisterCandidatePayload } from '../models/candidate.model';
import { TranslateService } from '@ngx-translate/core';
import { IApplication } from '../models/application.model';
import { Application } from '../classes/application.class';
import { CustomEncoder } from '../classes/encoder.class';
import { Pagination } from '../models/pagination.interface';
import { AUTH_TOKEN } from '../resources/auth-token';
import { SetupService } from './setup.service';
import { CountryCallingCode } from '../models/country-calling-codes.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CandidateRegisterService {

  applyingForUniversalJob: boolean;
  continuingApplication = false;

  private _callingCodes: CountryCallingCode[];

  constructor(
    private http: HttpClient,
    private router: Router,
    private configService: AppConfigService,
    private translateService: TranslateService,
    private setupService: SetupService,
    protected toastr: ToastrService
  ) {}

  registerApplication(payload: RegisterCandidatePayload): Observable<Application> {
    return this.createApplication(payload)
      .pipe(
        mergeMap((application: Application) => {
          const answers = JSON.parse(sessionStorage.getItem('answers'));

          if (!this.applyingForUniversalJob &&
              answers !== null &&
              !this.continuingApplication &&
              (application.jobApplication.inhouseQuestion || application.jobApplication.knockoutQuestions)) {
            return this.submitKnockoutAndInhouseQuestionAnswers(
              application,
              answers
            ).pipe(
              map(() => application)
            );
          } else {
            this.continuingApplication = false;
            return of(application);
          }
        })
    );
  }

  createCandidate(email: string, companyGuid: string): Observable<CreateCandidateResponse> {
    const { isEnterprise, isAllJobs, id, guid, companies } = this.configService.organization;
    const storedCompanyId = sessionStorage.getItem('companyId');

    const companyGUID = isAllJobs
      ? companyGuid
      : isEnterprise
        ? companies.find(cmp => cmp.id.toString() === storedCompanyId.toString()).guid
        : guid;

    const companyID = isEnterprise || isAllJobs ? storedCompanyId : id;

    return this.http
      .post(
        `${environment.candidates}/${companyGUID}`,
        {
          email: email.toLowerCase(),
          company: companyID,
        }
      )
      .pipe(
        map(({data}: Pagination<CreateCandidateResponse>) => data[0])
      );
  }

  createApplication(registerCandidatePayload: RegisterCandidatePayload): Observable<Application> {
    const { candidate, universalJobId, additionalJobsToApplyTo, phone, name, email, linkedinProfile } = registerCandidatePayload;


    const payload: CreateApplicationPayload = {
      candidate: candidate.id,
      candidateAppliedWithName: name,
      phoneNumber: phone,
      linkedinProfile: linkedinProfile
    };

    if (universalJobId) {
      payload.universalJob = universalJobId;
      this.applyingForUniversalJob = true;
    } else {
      payload.job = Number(sessionStorage.getItem('jobId'));
      this.applyingForUniversalJob = false;
    }

    if (additionalJobsToApplyTo && additionalJobsToApplyTo.length) {
      payload.additionalJobsToApplyTo = additionalJobsToApplyTo;
    }

    const referrer = this.configService.referrer;

    if (referrer) {
      payload.referer = referrer;
    }

    return this.http
      .post(
        `${environment.applications}/${candidate.guid}`,
        payload,
        {
          headers: new HttpHeaders({ 'Accept-language': this.translateService.currentLang }),
        }
      )
      .pipe(
        map(({data}: Pagination<IApplication>) => data[0]),
        catchError((errorResponse: HttpErrorResponse) => {
          if (
            errorResponse.status === 409
          ) {
            this.continuingApplication = true;
            return this.continueApplication(payload.universalJob, payload.job, email);
          } else {
            sessionStorage.removeItem('jobId');
            sessionStorage.removeItem('companyId');
            sessionStorage.removeItem('companyDomain');
            sessionStorage.removeItem('jobCompanyLogo');

            this.router.navigate(['/']);
            return throwError(() => errorResponse);
          }
        }),
        map((application: IApplication) => new Application(application)),
      );
  }

  submitReferer(type: string, referer: string, entityGuid?: string): Observable<any> {

    const payload: {type: string, referer: string, entityGuid?: string} = {
      type,
      referer,
      entityGuid
    };

    return this.http.post(
      environment.referrer,
      payload,
      {
        headers: new HttpHeaders({ Authorization: AUTH_TOKEN }),
      }
    );

  }

  getRefererList(type: string, entityGuid?: string): Observable<any> {
    let params = new HttpParams();

    params = params.append('type', type);
    params = params.append('entityGuid', entityGuid);

    return this.http.get(
      environment.referrer,
      {
        headers: new HttpHeaders({ Authorization: AUTH_TOKEN }),
      }
    );
  }


  submitKnockoutAndInhouseQuestionAnswers({jobApplication}: Application, answers: [number]): Observable<string> {
    const jobApplicationGUID = jobApplication.guid;

    if (jobApplication.inhouseQuestion && jobApplication.knockoutQuestions) {
      const inhouseAnswer = answers[0];
      answers.shift();

      return this.submitInhouseQuestionAnswer(jobApplicationGUID, inhouseAnswer)
        .pipe(
          switchMap(() => this.submitKnockoutQuestionsAnswers(jobApplicationGUID, answers))
        );
    }

    if (jobApplication.inhouseQuestion) {
      const inhouseAnswer = answers[0];

      return this.submitInhouseQuestionAnswer(jobApplicationGUID, inhouseAnswer);
    }

    return this.submitKnockoutQuestionsAnswers(jobApplicationGUID, answers);
  }

  submitKnockoutQuestionsAnswers(jobApplicationGUID: string, answers: [number]): Observable<string> {
    return this.http.put<string>(
      `${environment.applications}/${jobApplicationGUID}/knockout_questions/save_answers`,
      { selectedAnswers: answers }
    );
  }

  submitInhouseQuestionAnswer(jobApplicationGUID: string, answerIndex: number): Observable<string> {
    return this.http.put<string>(
      `${environment.inhouseQuestion}/${jobApplicationGUID}`,
      { answer: answerIndex + 1 }
    );
  }

  continueApplication(universalJob: number, jobId: number, candidateEmail: string): Observable<IApplication> {
    let params = new HttpParams({encoder: new CustomEncoder()});

    if (universalJob) {
      params = params.append('universalJob', `${universalJob}`);
    }

    if (jobId) {
      params = params.append('jobIds', `${jobId}`);
    }

    params = params.append('candidateEmail', candidateEmail);

    return this.http.get(environment.applications, { params })
      .pipe(
        map(({data}: Pagination<IApplication>) => data[0]),
        mergeMap((application: IApplication) => {
          if (application.boundToApplication) {
            return this.setupService.getBaseApplicationInfo(application.boundToApplication.guid);
          } else {
            return of(application);
          }
        }),
        map((application: IApplication) => {
          return { ...application, continueApplication: true};
        })
      );
  }

  checkIfCandidateAlreadyApplied(candidateId: number): Observable<{guid: string}[]> {
    const jobId = sessionStorage.getItem('jobId');

    return this.http.get<{guid: string}[]>(`${environment.candidates}/${candidateId}/jobs/${jobId}`);
  }

  getCountryCallingCodes(): Observable<CountryCallingCode[]> {
    const origin = window.location.origin;

    if (this._callingCodes) {
      return of(this._callingCodes);
    }

    return this.http.get<CountryCallingCode[]>(`${origin}/assets/country-calling-codes.json`)
      .pipe(
        tap((callingCodes: CountryCallingCode[]) => this._callingCodes = callingCodes)
      );
  }
}
